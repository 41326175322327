// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-artwork-js": () => import("./../../../src/templates/artwork.js" /* webpackChunkName: "component---src-templates-artwork-js" */),
  "component---src-templates-entry-js": () => import("./../../../src/templates/entry.js" /* webpackChunkName: "component---src-templates-entry-js" */),
  "component---src-templates-pastentry-js": () => import("./../../../src/templates/pastentry.js" /* webpackChunkName: "component---src-templates-pastentry-js" */)
}

